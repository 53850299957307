import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import * as Sentry from "@sentry/browser";

import { FormattedMessage } from "react-intl";
import { resolveUserLocale } from "../index";
import analyticsClient from "../utils/analyticsPlatformClient";
import { getSessionToken } from "../redux/modules/session";
import { datadogRum } from "@datadog/browser-rum-slim";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  onAckError = () => {
    this.setState({ error: null });

    // <FormattedMessage/> is insuitable for localizing the showReportDialog,
    // so we'll use intl.formatMessage instead
    const { intl } = this.props;

    Sentry.showReportDialog({
      title: intl.formatMessage({
        id: "sentry_user_feedback_title",
        defaultMessage: "It looks like we’re having issues."
      }),
      subtitle: intl.formatMessage({
        id: "sentry_user_feedback_subtitle",
        defaultMessage: "Our team has been notified."
      }),
      subtitle2: intl.formatMessage({
        id: "sentry_user_feedback_subtitle2",
        defaultMessage: "If you’d like to help, tell us what happened below."
      }),
      labelName: intl.formatMessage({
        id: "sentry_user_feedback_labelName",
        defaultMessage: "Name"
      }),
      labelEmail: intl.formatMessage({
        id: "sentry_user_feedback_labelEmail",
        defaultMessage: "Email"
      }),
      labelComments: intl.formatMessage({
        id: "sentry_user_feedback_labelComments",
        defaultMessage: "What happened?"
      }),
      labelClose: intl.formatMessage({
        id: "sentry_user_feedback_labelClose",
        defaultMessage: "Close"
      }),
      labelSubmit: intl.formatMessage({
        id: "sentry_user_feedback_labelSubmit",
        defaultMessage: "Submit"
      }),
      errorGeneric: intl.formatMessage({
        id: "sentry_user_feedback_errorGeneric",
        defaultMessage: "An unknown error occurred. Please try again."
      }),
      errorFormEntry: intl.formatMessage({
        id: "sentry_user_feedback_errorFormEntry",
        defaultMessage: "The highlighted fields are invalid - please try again."
      }),
      successMessage: intl.formatMessage({
        id: "sentry_user_feedback_successMessage",
        defaultMessage: "Thank you for the feedback - it has been sent."
      })
    });
  };

  componentDidCatch(error, errorInfo) {
    const { storefrontAccountCode, selectedPaymentMethodId } = this.props;
    this.setState({ error });

    // Send sessionErrorEvent for client-side errors
    analyticsClient.sendEvent("sessionErrorEvent", this.props.sessionToken, {
      errorMessage: error.toString(), // Convert error to string for readability
      errorCode: "UNHANDLED_JS_ERROR" // Static error code for client-side exceptions
    });

    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);

    Sentry.withScope((scope) => {
      // Add locale, sf, paymentMethodId
      scope.setTag("locale", resolveUserLocale());
      scope.setTag("storefront", storefrontAccountCode);
      scope.setTag("paymentMethodId", selectedPaymentMethodId);
      // Add all other tags from context (browser version, OS, etc)
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      // In case of unrecoverable render error, show fallback UI
      return (
        <div className="error-page">
          <div className="error-box">
            <h1>
              <FormattedMessage id="error_sentry_title" defaultMessage="ERROR" />
            </h1>
            <div className="error-text">
              <FormattedMessage
                id="error_sentry_text"
                defaultMessage="Looks like something broke. Feel free to let us know what happened, or close this window."
              />
            </div>
            <button className="btn btn-default" onClick={this.onAckError}>
              <FormattedMessage id="error_sentry_button" defaultMessage="Report" />
            </button>
          </div>
        </div>
      );
    } else {
      // When there's no error, render children untouched
      return this.props.children;
    }
  }
}

const getStorefront = (state) => {
  if (state && state.paymentOptions) {
    return state.paymentOptions.storefrontAccountCode;
  } else {
    return "unknown";
  }
};

const getPaymentMethodId = (state) => {
  if (state && state.paymentOptions) {
    return state.paymentOptions.selectedPaymentMethodId;
  } else {
    return "unknown";
  }
};

const mapStateToProps = (state) => ({
  storefrontAccountCode: getStorefront(state),
  selectedPaymentMethodId: getPaymentMethodId(state),
  sessionToken: getSessionToken(state)
});

// injectIntl gives us access to intl.formatMessage() for retrieving loc strings in plaintext
export default injectIntl(connect(mapStateToProps)(ErrorBoundary));
